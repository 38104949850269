import React, { useState } from 'react'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import './style.scss'
import WebpImage from '../WebpImage';


const Privacy = () => {
  const [activeCategory, setActiveCategory] = useState('info-we-collect')

  const handleCategoryClick = (category, isMobile) => {
    if (activeCategory === category && isMobile) {
      setActiveCategory('')
    } else {
      setActiveCategory(category)
    }
  }

  const arrow = (category) => {
    return (
      <span className='caret-wrapper'>
        {activeCategory === category ?
          <WebpImage fileName='assets/CaretImg/down-caret.svg' />
        :
          <WebpImage fileName="assets/CaretImg/up-caret.svg" />
        }
      </span>
    )
  }

  return (
    <>
      <div className="privacy-section mobile-only">
        <ul className="mobile-privacy-list">
          <li className={`privacy-category ${activeCategory === 'info-we-collect' ? 'active' : ''}`}  onClick={() => handleCategoryClick('info-we-collect', true)}>
            <a>
              What Information Do We Collect?
            </a>
            {arrow('info-we-collect')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'info-we-collect' ? 'active' : ''}`}>
            {/* <TermsBlock copy={whatInformation.copy}/> */}
            <div className="tertiary-block">
              <div className=''>
                <p>
                  This Privacy Policy was last updated on July 6, 2020.
                </p>
                <p className="legal-text">
                  Vision Path, Inc. and its affiliates and subsidiaries (collectively, “we,” “our,” or “us”) operate the Hubble® and ContactsCart brands, including the online stores available at www.hubblecontacts.com, www.contactscart.com, and other landing pages or websites made available by us or our service providers (the “online stores”). The purpose of this Privacy Policy is to inform you about the information we may collect from you, how we use such information, and the choices you may have regarding such information. This Privacy Policy applies to information we collect about you, including through our websites, websites operated by our service providers and marketing partners, and any other website that links to this Privacy Policy. By visiting the online stores, becoming a Hubble or ContactsCart customer, and/or sharing your contact information with us, you are accepting and consenting to the practices described in this Privacy Policy, as well as our Terms of Service for <Link to="https://tos.hubblecontacts.com/">Hubble</Link> and <Link to="/visionpath-terms-of-service">ContactsCart</Link>, now and as amended by us from time to time.
                </p>

                <p id="info-we-collect">
                  <strong>1. What Information Do We Collect?</strong>
                </p>
                <p className="legal-text">
                  We collect several categories of information about you. We collect the information you provide directly
                  to us, which is often personal data (as defined in this policy). We, our service providers, social networks,
                  and other of our marketing partners (as each are defined in this policy) also automatically collect
                  information about you. That information is often usage data (as defined in this policy), but when that
                  automatically collected information can directly or indirectly identify you, we treat it as personal data.
                  Finally, we may aggregate personal data and/or usage data, and that aggregate data (as defined in this
                  policy) is a separate type of information.
                </p>

                <p className="ml-5">1.1. Information You Provide</p>
                <p className="legal-text">
                  We and our service providers collect any information that you provide when you use the online stores,
                  including when you:
                </p>

                <ul>
                  <li>
                    create an account;
                  </li>
                  <li>
                    purchase a product or subscribe to one of our subscription products; or
                  </li>
                  <li>
                    otherwise contact us with a question, comment, or request, whether through the online stores,
                    including any associated interactive features, or through our social media channels.
                  </li>
                </ul>

                <p className="legal-text">
                  The information that you provide us and our service providers can include, but is not limited to:
                </p>

                <ul>
                  <li>
                    your name;
                  </li>
                  <li>
                    your contact information, such as your email address, phone number, and social media
                    usernames;
                  </li>
                  <li>
                    your mailing address, including your street address, city, state, zip/postal code, and country;
                  </li>
                  <li>
                    your account user name and password;
                  </li>
                  <li>
                    your contact lens prescription and your eye care provider;
                  </li>
                  <li>
                    information required to process transactions, such as your payment information (i.e., your
                    credit or debit card information) and your shipping information; and
                  </li>
                  <li>
                    any information you provide when you contact us.
                  </li>
                </ul>

                <p className="legal-text">
                  Where this information, and other information described in this Privacy Policy, identifies you as a
                  specific, identifiable individual, we refer to it as “personal data” in this Privacy Policy. Whether or not to
                  provide such personal data is your own choice. But if you choose not to provide the information we
                  request, you may be unable to purchase products, or access certain services, offers and content on our
                  websites. In addition, we may need to contact you via phone, email, or mail to address questions or
                  issues specific to your order, even if you have opted to not receive marketing communications from us.
                </p>

                <p className="ml-5">1.2. Other Information We Collect.</p>
                <p className="legal-text">
                  We and our service providers also may automatically collect certain technical and usage information
                  from your computer or mobile device when you use the online stores or otherwise interact with us. We
                  refer to that information as “usage data” in this Privacy Policy. Usage data may include information such
                  as your Internet Protocol address, your browser type, your operating system, the pages you view on the
                  online stores and your interaction with elements of the online stores, the pages you view before and/or
                  after you access the online stores, and the search terms you enter on the online stores. Usage data does
                  not contain identifiers like your email address or your phone number that are typically used to identify
                  you as an individual. Collecting usage data allows us to personalize your experience if you return to the
                  online stores, and to improve the online stores and the services we provide. We and our service
                  providers may collect this information using “cookies” (which are small text files that are saved on your
                  computer using your web browser when you access the online stores and identified when you return),
                  “web beacons” or “pixels” (which are unobtrusive elements of a website that may identify you as a
                  visitor to the online stores), or other similar technologies (collectively, “tracking technologies”). We also
                  use analytics tools when you visit the online stores to better understand how you use the online stores.
                </p>
                <p className="legal-text">
                  The online stores contain integrations with social networks, third-party advertising networks, and other
                  platforms (collectively, “social networks”), including tracking technologies through which usage data is
                  collected by those social networks. These social networks may use usage data for advertising and
                  analytics purposes, and they may track your activities over time and across different websites and social
                  media. These social networks’ use of and collection through tracking technologies is subject to those
                  social networks’ privacy policies, not this one.
                </p>
                <p className="legal-text">
                  We also collect information from other trusted sources to support, update, supplement, or validate the
                  information you provide to us or that we collect. For example, we use a service to confirm that the
                  shipping address you may provide to us is accurate.
                </p>

                <p className="ml-5">1.3. Aggregate data</p>
                <p className="legal-text">
                  We may group or categorize any of the foregoing personal data or usage data, and/or combine it with
                  publicly available information and other online and offline information we receive from or crossreference with our service providers, marketing partners and others, including providers of third-party
                  cookie and other third-party browsing and usage data. Because this information is about groups or
                  categories of individuals, it cannot reasonably be used to identify you.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'how-we-use-this-information' ? 'active' : ''}`} onClick={() => handleCategoryClick('how-we-use-this-information', true)}>
            <a>
              How Do We Use This Information?
            </a>
            {arrow('how-we-use-this-information')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'how-we-use-this-information' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="how-we-use-this-information">
                  <strong>2. How Do We Use This Information?</strong>
                </p>
                <p className="legal-text">
                  We and our service providers may use personal data and usage data for a number of purposes,
                  including, but not limited to:
                </p>

                <ul>
                  <li>
                    operating the online stores, including providing to you the features and services available through the online stores;
                  </li>
                  <li>
                    providing you with the products you purchase and the services or information you request;
                  </li>
                  <li>
                    troubleshooting any issues with the online stores that may arise;
                  </li>
                  <li>
                    delivering marketing communications, promotional materials, or advertisements that may be of interest to you, or excluding you from receiving such communications, materials, or advertisements;
                  </li>
                  <li>
                    providing you with information about the online stores or required notices;
                  </li>
                  <li>
                    customizing your experience when using the online stores, such as by providing interactive or
                    personalized elements on the online stores;
                  </li>
                  <li>
                    allowing us to improve the online stores and the services we provide, such as by better tailoring
                    our content to our users' and your specific needs and preferences;
                  </li>
                  <li>
                    generating and analyzing statistics about your use of the online stores;
                  </li>
                  <li>
                    helping our advertisers, sponsors, and partners better understand our users; and
                  </li>
                  <li>
                    detecting, preventing, and responding to fraud, intellectual property infringement, violations of
                    our Terms of Service, violations of law, or other misuse of the online stores
                  </li>
                </ul>
                <p>
                  We also may use aggregate data for any of these purposes, including delivering targeted advertisements on the online stores or through other third-party websites or services that are based on your previous online activity on the online stores and on other third-party websites or mobile applications. In addition, where we maintain personal data, we and our service providers may de-identify your information by removing information that can reasonably be used to identify you (e.g., name, phone number, email address) and use that de-identified information for any purpose.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'circumstances-we-disclose-information' ? 'active' : ''}`} onClick={() => handleCategoryClick('circumstances-we-disclose-information', true)}>
            <a>
              How Do We Disclose This Information?
            </a>
            {arrow('circumstances-we-disclose-information')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'circumstances-we-disclose-information' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="circumstances-we-disclose-information">
                  <strong>3. Under What Circumstances Do We Disclose This Information?</strong>
                </p>
                <p className="legal-text">
                  We may disclose personal data, usage data, and aggregate data to affiliated companies, service
                  providers, social networks, and marketing partners, including as follows:
                </p>

                <p className="ml-5">3.1. Service Providers.</p>
                <p className="legal-text">
                  Vendors who work on our behalf and who have agreed to use the information in furtherance of our
                  operations may have access to personal data, usage data, and aggregate data. We refer to these
                  vendors throughout this Privacy Policy as our “service providers.” We may utilize service providers for
                  services including, but not limited to, payment processing services; order fulfillment services; targeting
                  and delivering marketing communications, promotional materials, or advertisements that may be of
                  interest to you and/or others; data analysis and management; and so forth.
                </p>

                <p className="ml-5">3.2. Social Networks and Marketing Partners</p>
                <p className="legal-text">
                  We may partner with other companies, including social networks, to target and deliver marketing
                  communications, promotional materials, or advertisements regarding our business and/or our partners’
                  businesses; to exclude you from marketing, promotional, or advertising campaigns; or to identify other
                  groups of individuals who may wish to receive advertisements or communications from or our partners.
                  We refer to these companies throughout this Privacy Policy as our “marketing partners.” Through these
                  partnerships we may share or receive, or share or receive access to, the types of data described in this
                  privacy policy, including personal data. Note that, in many cases, once we share your personal data with
                  a marketing partner, the information received by the marketing partner or social network is controlled
                  by that company and becomes subject to its privacy practices.
                </p>

                <p className="ml-5">3.3. Change of Control.</p>
                <p className="legal-text">
                  In the event we prepare for (including through the due diligence process) or undergo undergo a merger,
                  acquisition by another company, bankruptcy, restructuring, joint venture, sale of all or a portion of our
                  assets, or other corporate transaction, personal data about you likely will be included in the assets
                  transferred in connection with that corporate transaction. You agree that we can transfer such
                  information in those circumstances without your further consent. Should such a transaction occur, we
                  will make reasonable efforts to request that the new owner or combined entity (as applicable) either
                  (a) follow this Privacy Policy or (b) provide you prior notice so that you can act accordingly.
                </p>
                <p className="ml-5">3.4. Other Scenarios.</p>
                <p className="legal-text">
                  We reserve the right to disclose personal data about you:
                </p>
                <ul>
                  <li>
                    to comply with law, such as pursuant to a subpoena, court order or other legal process, or to
                    comply with government reporting obligations;
                  </li>
                  <li>
                    when we believe in good faith that disclosure is necessary (a) to protect our rights, the integrity
                    of the online stores, or your safety or the safety of others, or (b) to detect, prevent, or respond
                    to fraud, intellectual property infringement, violations of our Terms of Use, violations of law, or
                    other misuse of the online stores; and
                  </li>
                </ul>
                <p className="legal-text">
                  The online stores also may contain third-party links. You acknowledge and agree that we are not
                  responsible for the collection and use of your information by such third parties that are not under our
                  control. We encourage you to review the privacy policies of each website you visit.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'where-information-is-processed' ? 'active' : ''}`} onClick={() => handleCategoryClick('where-information-is-processed', true)}>
            <a>
              Where is This Information Processed?
            </a>
            {arrow('where-information-is-processed')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'where-information-is-processed' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="where-information-is-processed">
                  <strong>4. Where is This Information Processed?</strong>
                </p>
                <p className="legal-text">
                  Information collected through or in connection with the online stores will be processed in and subject to
                  the laws of the United States, which, if you are not located in the United States, may not provide the
                  same level of protection for your personal information as your home country, and may be available to
                  the United States government or its agencies pursuant to applicable legal authority in the United States.
                  In addition, we may transfer your information outside the United States to our affiliates, business
                  partners, and service providers located in other countries. By using the online stores, you consent to
                  such transfer to, and processing in, the United States and these other countries.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'how-information-secured' ? 'active' : ''}`} onClick={() => handleCategoryClick('how-information-secured', true)}>
            <a>
              How is Your Information Secured?
            </a>
            {arrow('how-information-secured')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'how-information-secured' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="how-information-secured">
                  <strong>5. How is Your Information Secured?</strong>
                </p>
                <p className="legal-text">
                  We maintain safeguards designed to protect the information collected by the online stores. However, no
                  information system can be 100% secure, so we cannot guarantee the absolute security of your
                  information. Moreover, we are not responsible for the security of information you transmit to the online
                  stores over networks that we do not control, including the Internet and wireless networks.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'choices' ? 'active' : ''}`} onClick={() => handleCategoryClick('choices', true)}>
            <a>
              Your Choices
            </a>
            {arrow('choices')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'choices' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="choices">
                  <strong>6. Your Choices</strong>
                </p>
                <p className="legal-text">
                  If you do not want the online stores to collect information through the use of cookies or other tracking
                  technologies, you may be able to set your web browser to reject the online stores’ tracking technology.
                  Each browser is different, so you should check your browser’s “Help” menu to learn how to change your
                  preferences. If you do, however, the online stores may not function as intended.
                </p>
                <p className="legal-text">
                  The online stores do not currently respond to web browser “Do Not Track” signals or other mechanisms
                  that provide a method to opt out of the collection of information over time and across websites and
                  online services you may use following your visit to the online stores. If we do so in the future, we will
                  describe how we do so in this Privacy Policy. Visit the following website, www.allaboutdnt.org, for more
                  information on this developing area.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'childrens-information' ? 'active' : ''}`} onClick={() => handleCategoryClick('childrens-information', true)}>
            <a>
              Children’s Information
            </a>
            {arrow('childrens-information')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'childrens-information' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="childrens-information">
                  <strong>7. Children’s Information</strong>
                </p>
                <p className="legal-text">
                  The online stores are not directed to, nor do we knowingly collect information from, children under the
                  age of 13. If you become aware that your child or any child under your care has provided us with
                  information without your consent, please contact us at the contact information listed below.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'changes-to-privacy-policy' ? 'active' : ''}`} onClick={() => handleCategoryClick('changes-to-privacy-policy', true)}>
            <a>
              Changes To This Privacy Policy
            </a>
            {arrow('changes-to-privacy-policy')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'changes-to-privacy-policy' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="changes-to-privacy-policy">
                  <strong>8. Changes to this Privacy Policy</strong>
                </p>
                <p className="legal-text">
                  If we update this Privacy Policy, we will notify you by posting a new Privacy Policy in this section of the
                  online stores. If we make any revisions that materially change the ways in which we use or share the
                  information previously collected from you through the online stores, we will give you the opportunity to
                  consent to such changes before applying them to that information. The effective date of this Privacy
                  Policy is January 1, 2020.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'contact-us' ? 'active' : ''}`} onClick={() => handleCategoryClick('contact-us', true)}>
            <a>
              Contact Us
            </a>
            {arrow('contact-us')}
          </li>
          <div className={`term-wrapper ${activeCategory === 'contact-us' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="contact-us">
                  <strong>9. Contact Us</strong>
                </p>
                <p className="legal-text">
                  If you have any questions about this Privacy Policy or our use of your information collected through the
                  online stores, please contact us at privacy@hubblecontacts.com or privacy@contactscart.com.
                </p>
              </div>
            </div>
          </div>

          <li className={`privacy-category ${activeCategory === 'information-for-california-customers' ? 'active' : ''}`} onClick={() => handleCategoryClick('information-for-california-customers', true)}>
            <a>
              Information For California Customers
            </a>
            {arrow('information-for-california-customers')}
          </li>
          {/* <p id="information-for-california-customers">
            <strong>10. Information For California Customers</strong>
          </p> */}
          <div className={`term-wrapper ${activeCategory === 'information-for-california-customers' ? 'active' : ''}`}>
            <div className="tertiary-block">
              <div className=''>
                <p id="information-for-california-customers">
                  <p className="text-center font-weight-bold"><u>Additional Disclosures for California Consumers</u></p>
                </p>

                <p className="font-weight-bold">Information We Collect</p>
                <p className="legal-text">
                California law requires that we describe the categories of personal information we collect about
                California consumers. We collect information that identifies, relates to, describes, is reasonably capable
                of being associated with, or could reasonably be linked, directly or indirectly, with a particular California
                consumer or household (“CCPA Personal Information”). This information is collected from a few sources,
                including directly from consumers, from our service providers to whom consumers have provided their
                information, and automatically when consumers use or interact with our online stores. We have
                collected the following categories of CCPA Personal Information from California consumers within the
                last twelve (12) months:
                </p>
                <li><strong>Identifiers</strong> (e.g., your name, email address, phone number, social media usernames, account
                user name and password, IP address)</li>
                <li><strong>Health & Medical Information</strong> (Cal. Civ. Code section 1798.80(e)) (e.g., your contact lens
                prescription, your eye care provider)</li>
                <li><strong>Commercial Information</strong> (e.g., payment information such as credit or debit card information,
                shipping information, records of transactions / subscriptions)</li>
                <li><strong>Location Information</strong> (e.g., your shipping and billing address, the state where you are located)</li>
                <li><strong>Internet Activity</strong> (e.g., browser type; operating system; the pages you view on the online stores
                and your interaction with elements of the online stores, the pages you view before and/or after
                you access the online stores, and the search terms you enter on the online stores)</li>
                <p className="legal-text">
                For more detail about the types of information we collect, please refer to the “What Information Do We
                Collect?” section of the Privacy Policy. For information about our business or commercial purpose(s) for
                collecting, or possibly sharing, CCPA Personal Information, please refer to the “How Do We Use This
                Information?” section of the main Privacy Policy
                </p>
                <p className="font-weight-bold">How We Share Information</p>
                <p>We may share your CCPA Personal Information with third parties as described in the “Under What
                Circumstances Do We Disclose This Information?” section of the main Privacy Policy. California law also
                requires that we provide you with information about certain disclosures of CCPA Personal Information
                to third parties, where the disclosures are made for “business purposes,” such as information shared
                with service providers performing business functions on our behalf, social networks and marketing
                partners providing advertising services on our behalf; or entities engaged in a business transfer/merger,
                who may receive information in the context of a change of control. We may disclose the following
                categories of information to each of these recipients: identifiers, personal information categories listed
                in the California Customer Records statute, commercial information, and internet activity</p>
                <p className="font-weight-bold">California Rights</p>
                <p className="legal-text">California law grants certain rights to California consumers. These include the rights to:</p>
                <li>Access specific pieces of Personal Data (“right to access”)</li>
                <li>Learn about how we process and share Personal Data (“right to know”)</li>
                <li>Request deletion of Personal Data we collected from you (“right to request deletion”)</li>
                <li>Not to be discriminated against as a result of exercising these rights</li>
                <p className="legal-text">
                  If you are a California consumer and have any questions regarding our collection or use of your CCPA
                  Personal Information, or if you’d like to exercise the rights to access, know, or request deletion: please
                  contact us at privacy@hubblecontacts.com or privacy@contactscart.com, or write us:
                </p>
                <p className="legal-text">
                  California Privacy Rights <br/>
                  Vision Path, Inc.<br/>
                  PO Box 20589<br/>
                  New York, NY 10023<br/>
                </p>
                <p className="legal-text">
                Only you or a person you authorize to act on your behalf may make a request related to your CCPA
                Personal Information. A request to exercise any of these rights must (1) provide sufficient information
                that allows us to reasonably verify that you are the person about whom we collected information (or an
                authorized representative of that person); and (2) describe your request with sufficient detail that
                allows us to understand, evaluate, and respond to your request. We will verify your identity by asking
                you to confirm certain details regarding your account and/or your subscription. In certain cases, we may
                need to ask for more information. We may not be able to respond to your request or provide you with
                the information you requested if we are unable to verify your identity (or establish the authority of an
                authorized agent acting on your behalf).
                Authorized agents wishing to exercise rights on behalf of a California consumer should submit requests
                to privacy@hubblecontacts.com or privacy@contactscart.com along with a copy of the consumer’s
                signed authorization designating you as their agent.
                If you do not have an account, while you may contact us at privacy@hubblecontacts.com or
                privacy@contactscart.com with questions or concerns, we may not be able to respond to requests to
                exercise your rights under the CCPA. Because we only collect limited information about individuals
                without an account, we are unable to verify requests from non-accountholders to the standard required
                by the CCPA.
                Additionally, under California Civil Code Section 1798.83, California residents have the right to request in
                writing from businesses with whom they have an established business relationship, (a) a list of the
                categories of personal information that a business has disclosed to third parties during the immediately
                preceding calendar year for the third parties’ direct marketing purposes and (b) the names and
                addresses of such third parties. To exercise this right, please contact us at the email addresses above.
                </p>
              </div>
            </div>
          </div>
        </ul>
      </div>

      <div className="privacy-section grid-section section desktop-only">
        <div className='row'>
          <div className='col-md-4'>
            <div className="table-of-contents">
              <ul className="side-nav">
                <li className={`privacy-category ${activeCategory === 'info-we-collect' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('info-we-collect', false)
                      scrollTo('.info-we-collect')
                    }}>
                  <a href="#info-we-collect">
                    1. What Information Do We Collect?
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'how-we-use-this-information' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('how-we-use-this-information', false)
                      scrollTo('.how-we-use-this-information')
                    }}>
                  <a href="#how-we-use-this-information">
                    2. How Do We Use This Information?
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'circumstances-we-disclose-information' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('circumstances-we-disclose-information', false)
                      scrollTo('.circumstances-we-disclose-information')
                    }}>
                  <a href="#circumstances-we-disclose-information">
                    3. How Do We Disclose This Information?
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'where-information-is-processed' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('where-information-is-processed', false)
                      scrollTo('.where-information-is-processed')
                    }}>
                  <a href="#where-information-is-processed">
                    4. Where is This Information Processed?
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'how-information-secured' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('how-information-secured', false)
                      scrollTo('.how-information-secured')
                    }}>
                  <a href="#how-information-secured">
                    5. How is Your Information Secured?
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'choices' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('choices', false)
                      scrollTo('.choices')
                    }}>
                  <a href="#choices">
                    6. Your Choices
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'childrens-information' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('childrens-information', false)
                      scrollTo('.childrens-information')
                    }}>
                  <a href="#childrens-information">
                    7. Children’s Information
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'changes-to-privacy-policy' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('changes-to-privacy-policy', false)
                      scrollTo('.changes-to-privacy-policy')
                    }}>
                  <a href="#changes-to-privacy-policy">
                    8. Changes to this Privacy Policy
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'contact-us' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('contact-us', false)
                      scrollTo('.contact-us')
                    }}>
                  <a href="#contact-us">
                    9. Contact Us
                  </a>
                </li>
                <li className={`privacy-category ${activeCategory === 'information-for-california-customers' ? 'active' : ''}`}
                  onClick={() => {
                      handleCategoryClick('information-for-california-customers', false)
                      scrollTo('.information-for-california-customers')
                    }}>
                  <a href="#information-for-california-customers">
                    10. Important Notices for California Customers
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='col-md-8'>
            <p>
              This Privacy Policy was last updated on July 6, 2020.
            </p>
            <p className="legal-text">
              Vision Path, Inc. and its affiliates and subsidiaries (collectively, “we,” “our,” or “us”) operate the Hubble® and ContactsCart brands, including the online stores available at www.hubblecontacts.com, www.contactscart.com, and other landing pages or websites made available by us or our service providers (the “online stores”). The purpose of this Privacy Policy is to inform you about the information we may collect from you, how we use such information, and the choices you may have regarding such information. This Privacy Policy applies to information we collect about you, including through our websites, websites operated by our service providers and marketing partners, and any other website that links to this Privacy Policy. By visiting the online stores, becoming a Hubble or ContactsCart customer, and/or sharing your contact information with us, you are accepting and consenting to the practices described in this Privacy Policy, as well as our Terms of Service for <Link to="https://tos.hubblecontacts.com/">Hubble</Link> and <Link to="/visionpath-terms-of-service">ContactsCart</Link>, now and as amended by us from time to time.
            </p>

            <p id="info-we-collect" className="info-we-collect">
              <strong>1. What Information Do We Collect?</strong>
            </p>
            <p className="legal-text">
              We collect several categories of information about you. We collect the information you provide directly
              to us, which is often personal data (as defined in this policy). We, our service providers, social networks,
              and other of our marketing partners (as each are defined in this policy) also automatically collect
              information about you. That information is often usage data (as defined in this policy), but when that
              automatically collected information can directly or indirectly identify you, we treat it as personal data.
              Finally, we may aggregate personal data and/or usage data, and that aggregate data (as defined in this
              policy) is a separate type of information.
            </p>

            <p className="ml-5">1.1. Information You Provide</p>
            <p className="legal-text">
              We and our service providers collect any information that you provide when you use the online stores,
              including when you:
            </p>

            <ul>
              <li>
                create an account;
              </li>
              <li>
                purchase a product or subscribe to one of our subscription products; or
              </li>
              <li>
                otherwise contact us with a question, comment, or request, whether through the online stores,
                including any associated interactive features, or through our social media channels.
              </li>
            </ul>

            <p className="legal-text">
              The information that you provide us and our service providers can include, but is not limited to:
            </p>

            <ul>
              <li>
                your name;
              </li>
              <li>
                your contact information, such as your email address, phone number, and social media
                usernames;
              </li>
              <li>
                your mailing address, including your street address, city, state, zip/postal code, and country;
              </li>
              <li>
                your account user name and password;
              </li>
              <li>
                your contact lens prescription and your eye care provider;
              </li>
              <li>
                information required to process transactions, such as your payment information (i.e., your
                credit or debit card information) and your shipping information; and
              </li>
              <li>
                any information you provide when you contact us.
              </li>
            </ul>

            <p className="legal-text">
              Where this information, and other information described in this Privacy Policy, identifies you as a
              specific, identifiable individual, we refer to it as “personal data” in this Privacy Policy. Whether or not to
              provide such personal data is your own choice. But if you choose not to provide the information we
              request, you may be unable to purchase products, or access certain services, offers and content on our
              websites. In addition, we may need to contact you via phone, email, or mail to address questions or
              issues specific to your order, even if you have opted to not receive marketing communications from us.
            </p>

            <p className="ml-5">1.2. Other Information We Collect.</p>
            <p className="legal-text">
              We and our service providers also may automatically collect certain technical and usage information
              from your computer or mobile device when you use the online stores or otherwise interact with us. We
              refer to that information as “usage data” in this Privacy Policy. Usage data may include information such
              as your Internet Protocol address, your browser type, your operating system, the pages you view on the
              online stores and your interaction with elements of the online stores, the pages you view before and/or
              after you access the online stores, and the search terms you enter on the online stores. Usage data does
              not contain identifiers like your email address or your phone number that are typically used to identify
              you as an individual. Collecting usage data allows us to personalize your experience if you return to the
              online stores, and to improve the online stores and the services we provide. We and our service
              providers may collect this information using “cookies” (which are small text files that are saved on your
              computer using your web browser when you access the online stores and identified when you return),
              “web beacons” or “pixels” (which are unobtrusive elements of a website that may identify you as a
              visitor to the online stores), or other similar technologies (collectively, “tracking technologies”). We also
              use analytics tools when you visit the online stores to better understand how you use the online stores.
            </p>
            <p className="legal-text">
              The online stores contain integrations with social networks, third-party advertising networks, and other
              platforms (collectively, “social networks”), including tracking technologies through which usage data is
              collected by those social networks. These social networks may use usage data for advertising and
              analytics purposes, and they may track your activities over time and across different websites and social
              media. These social networks’ use of and collection through tracking technologies is subject to those
              social networks’ privacy policies, not this one.
            </p>
            <p className="legal-text">
              We also collect information from other trusted sources to support, update, supplement, or validate the
              information you provide to us or that we collect. For example, we use a service to confirm that the
              shipping address you may provide to us is accurate.
            </p>

            <p className="ml-5">1.3. Aggregate data</p>
            <p className="legal-text">
              We may group or categorize any of the foregoing personal data or usage data, and/or combine it with
              publicly available information and other online and offline information we receive from or crossreference with our service providers, marketing partners and others, including providers of third-party
              cookie and other third-party browsing and usage data. Because this information is about groups or
              categories of individuals, it cannot reasonably be used to identify you.
            </p>

            <p id="how-we-use-this-information" className="how-we-use-this-information">
              <strong>2. How Do We Use This Information?</strong>
            </p>
            <p className="legal-text">
              We and our service providers may use personal data and usage data for a number of purposes,
              including, but not limited to:
            </p>

            <ul>
              <li>
                operating the online stores, including providing to you the features and services available through the online stores;
              </li>
              <li>
                providing you with the products you purchase and the services or information you request;
              </li>
              <li>
                troubleshooting any issues with the online stores that may arise;
              </li>
              <li>
                delivering marketing communications, promotional materials, or advertisements that may be of interest to you, or excluding you from receiving such communications, materials, or advertisements;
              </li>
              <li>
                providing you with information about the online stores or required notices;
              </li>
              <li>
                customizing your experience when using the online stores, such as by providing interactive or
                personalized elements on the online stores;
              </li>
              <li>
                allowing us to improve the online stores and the services we provide, such as by better tailoring
                our content to our users' and your specific needs and preferences;
              </li>
              <li>
                generating and analyzing statistics about your use of the online stores;
              </li>
              <li>
                helping our advertisers, sponsors, and partners better understand our users; and
              </li>
              <li>
                detecting, preventing, and responding to fraud, intellectual property infringement, violations of
                our Terms of Service, violations of law, or other misuse of the online stores
              </li>
            </ul>
            <p>
              We also may use aggregate data for any of these purposes, including delivering targeted advertisements on the online stores or through other third-party websites or services that are based on your previous online activity on the online stores and on other third-party websites or mobile applications. In addition, where we maintain personal data, we and our service providers may de-identify your information by removing information that can reasonably be used to identify you (e.g., name, phone number, email address) and use that de-identified information for any purpose.
            </p>

            <p id="circumstances-we-disclose-information" className="circumstances-we-disclose-information">
              <strong>3. Under What Circumstances Do We Disclose This Information?</strong>
            </p>
            <p className="legal-text">
              We may disclose personal data, usage data, and aggregate data to affiliated companies, service
              providers, social networks, and marketing partners, including as follows:
            </p>

            <p className="ml-5">3.1. Service Providers.</p>
            <p className="legal-text">
              Vendors who work on our behalf and who have agreed to use the information in furtherance of our
              operations may have access to personal data, usage data, and aggregate data. We refer to these
              vendors throughout this Privacy Policy as our “service providers.” We may utilize service providers for
              services including, but not limited to, payment processing services; order fulfillment services; targeting
              and delivering marketing communications, promotional materials, or advertisements that may be of
              interest to you and/or others; data analysis and management; and so forth.
            </p>

            <p className="ml-5">3.2. Social Networks and Marketing Partners</p>
            <p className="legal-text">
              We may partner with other companies, including social networks, to target and deliver marketing
              communications, promotional materials, or advertisements regarding our business and/or our partners’
              businesses; to exclude you from marketing, promotional, or advertising campaigns; or to identify other
              groups of individuals who may wish to receive advertisements or communications from or our partners.
              We refer to these companies throughout this Privacy Policy as our “marketing partners.” Through these
              partnerships we may share or receive, or share or receive access to, the types of data described in this
              privacy policy, including personal data. Note that, in many cases, once we share your personal data with
              a marketing partner, the information received by the marketing partner or social network is controlled
              by that company and becomes subject to its privacy practices.
            </p>

            <p className="ml-5">3.3. Change of Control.</p>
            <p className="legal-text">
              In the event we prepare for (including through the due diligence process) or undergo undergo a merger,
              acquisition by another company, bankruptcy, restructuring, joint venture, sale of all or a portion of our
              assets, or other corporate transaction, personal data about you likely will be included in the assets
              transferred in connection with that corporate transaction. You agree that we can transfer such
              information in those circumstances without your further consent. Should such a transaction occur, we
              will make reasonable efforts to request that the new owner or combined entity (as applicable) either
              (a) follow this Privacy Policy or (b) provide you prior notice so that you can act accordingly.
            </p>
            <p className="ml-5">3.4. Other Scenarios.</p>
            <p className="legal-text">
              We reserve the right to disclose personal data about you:
            </p>
            <ul>
              <li>
                to comply with law, such as pursuant to a subpoena, court order or other legal process, or to
                comply with government reporting obligations;
              </li>
              <li>
                when we believe in good faith that disclosure is necessary (a) to protect our rights, the integrity
                of the online stores, or your safety or the safety of others, or (b) to detect, prevent, or respond
                to fraud, intellectual property infringement, violations of our Terms of Use, violations of law, or
                other misuse of the online stores; and
              </li>
            </ul>
            <p className="legal-text">
              The online stores also may contain third-party links. You acknowledge and agree that we are not
              responsible for the collection and use of your information by such third parties that are not under our
              control. We encourage you to review the privacy policies of each website you visit.
            </p>

            <p id="where-information-is-processed"  className="where-information-is-processed">
              <strong>4. Where is This Information Processed?</strong>
            </p>
            <p className="legal-text">
              Information collected through or in connection with the online stores will be processed in and subject to
              the laws of the United States, which, if you are not located in the United States, may not provide the
              same level of protection for your personal information as your home country, and may be available to
              the United States government or its agencies pursuant to applicable legal authority in the United States.
              In addition, we may transfer your information outside the United States to our affiliates, business
              partners, and service providers located in other countries. By using the online stores, you consent to
              such transfer to, and processing in, the United States and these other countries.
            </p>

            <p id="how-information-secured" className="how-information-secured">
              <strong>5. How is Your Information Secured?</strong>
            </p>
            <p className="legal-text">
              We maintain safeguards designed to protect the information collected by the online stores. However, no
              information system can be 100% secure, so we cannot guarantee the absolute security of your
              information. Moreover, we are not responsible for the security of information you transmit to the online
              stores over networks that we do not control, including the Internet and wireless networks.
            </p>

            <p id="choices" className="choices">
              <strong>6. Your Choices</strong>
            </p>
            <p className="legal-text">
              If you do not want the online stores to collect information through the use of cookies or other tracking
              technologies, you may be able to set your web browser to reject the online stores’ tracking technology.
              Each browser is different, so you should check your browser’s “Help” menu to learn how to change your
              preferences. If you do, however, the online stores may not function as intended.
            </p>
            <p className="legal-text">
              The online stores do not currently respond to web browser “Do Not Track” signals or other mechanisms
              that provide a method to opt out of the collection of information over time and across websites and
              online services you may use following your visit to the online stores. If we do so in the future, we will
              describe how we do so in this Privacy Policy. Visit the following website, www.allaboutdnt.org, for more
              information on this developing area.
            </p>

            <p id="childrens-information" className="childrens-information">
              <strong>7. Children’s Information</strong>
            </p>
            <p className="legal-text">
              The online stores are not directed to, nor do we knowingly collect information from, children under the
              age of 13. If you become aware that your child or any child under your care has provided us with
              information without your consent, please contact us at the contact information listed below.
            </p>

            <p id="changes-to-privacy-policy"  className="changes-to-privacy-policy">
              <strong>8. Changes to this Privacy Policy</strong>
            </p>
            <p className="legal-text">
              If we update this Privacy Policy, we will notify you by posting a new Privacy Policy in this section of the
              online stores. If we make any revisions that materially change the ways in which we use or share the
              information previously collected from you through the online stores, we will give you the opportunity to
              consent to such changes before applying them to that information. The effective date of this Privacy
              Policy is January 1, 2020.
            </p>

            <p id="contact-us"  className="contact-us">
              <strong>9. Contact Us</strong>
            </p>
            <p className="legal-text">
              If you have any questions about this Privacy Policy or our use of your information collected through the
              online stores, please contact us at privacy@hubblecontacts.com or privacy@contactscart.com.
            </p>
            <p id="information-for-california-customers">
              <strong>10. Information For California Customers</strong>
            </p>

            <p id="information-for-california-customers" className="information-for-california-customers">
              <p className="text-center font-weight-bold"><u>Additional Disclosures for California Consumers</u></p>
            </p>

            <p className="font-weight-bold">Information We Collect</p>
            <p className="legal-text">
            California law requires that we describe the categories of personal information we collect about
            California consumers. We collect information that identifies, relates to, describes, is reasonably capable
            of being associated with, or could reasonably be linked, directly or indirectly, with a particular California
            consumer or household (“CCPA Personal Information”). This information is collected from a few sources,
            including directly from consumers, from our service providers to whom consumers have provided their
            information, and automatically when consumers use or interact with our online stores. We have
            collected the following categories of CCPA Personal Information from California consumers within the
            last twelve (12) months:
            </p>
            <li><strong>Identifiers</strong> (e.g., your name, email address, phone number, social media usernames, account
            user name and password, IP address)</li>
            <li><strong>Health & Medical Information</strong> (Cal. Civ. Code section 1798.80(e)) (e.g., your contact lens
            prescription, your eye care provider)</li>
            <li><strong>Commercial Information</strong> (e.g., payment information such as credit or debit card information,
            shipping information, records of transactions / subscriptions)</li>
            <li><strong>Location Information</strong> (e.g., your shipping and billing address, the state where you are located)</li>
            <li><strong>Internet Activity</strong> (e.g., browser type; operating system; the pages you view on the online stores
            and your interaction with elements of the online stores, the pages you view before and/or after
            you access the online stores, and the search terms you enter on the online stores)</li>
            <p className="legal-text">
            For more detail about the types of information we collect, please refer to the “What Information Do We
            Collect?” section of the Privacy Policy. For information about our business or commercial purpose(s) for
            collecting, or possibly sharing, CCPA Personal Information, please refer to the “How Do We Use This
            Information?” section of the main Privacy Policy
            </p>
            <p className="font-weight-bold">How We Share Information</p>
            <p>We may share your CCPA Personal Information with third parties as described in the “Under What
            Circumstances Do We Disclose This Information?” section of the main Privacy Policy. California law also
            requires that we provide you with information about certain disclosures of CCPA Personal Information
            to third parties, where the disclosures are made for “business purposes,” such as information shared
            with service providers performing business functions on our behalf, social networks and marketing
            partners providing advertising services on our behalf; or entities engaged in a business transfer/merger,
            who may receive information in the context of a change of control. We may disclose the following
            categories of information to each of these recipients: identifiers, personal information categories listed
            in the California Customer Records statute, commercial information, and internet activity</p>
            <p className="font-weight-bold">California Rights</p>
            <p className="legal-text">California law grants certain rights to California consumers. These include the rights to:</p>
            <li>Access specific pieces of Personal Data (“right to access”)</li>
            <li>Learn about how we process and share Personal Data (“right to know”)</li>
            <li>Request deletion of Personal Data we collected from you (“right to request deletion”)</li>
            <li>Not to be discriminated against as a result of exercising these rights</li>
            <p className="legal-text">
              If you are a California consumer and have any questions regarding our collection or use of your CCPA
              Personal Information, or if you’d like to exercise the rights to access, know, or request deletion: please
              contact us at privacy@hubblecontacts.com or privacy@contactscart.com, or write us:
            </p>
            <p className="legal-text">
              California Privacy Rights <br/>
              Vision Path, Inc.<br/>
              PO Box 20589<br/>
              New York, NY 10023<br/>
            </p>
            <p className="legal-text">
            Only you or a person you authorize to act on your behalf may make a request related to your CCPA
            Personal Information. A request to exercise any of these rights must (1) provide sufficient information
            that allows us to reasonably verify that you are the person about whom we collected information (or an
            authorized representative of that person); and (2) describe your request with sufficient detail that
            allows us to understand, evaluate, and respond to your request. We will verify your identity by asking
            you to confirm certain details regarding your account and/or your subscription. In certain cases, we may
            need to ask for more information. We may not be able to respond to your request or provide you with
            the information you requested if we are unable to verify your identity (or establish the authority of an
            authorized agent acting on your behalf).
            Authorized agents wishing to exercise rights on behalf of a California consumer should submit requests
            to privacy@hubblecontacts.com or privacy@contactscart.com along with a copy of the consumer’s
            signed authorization designating you as their agent.
            If you do not have an account, while you may contact us at privacy@hubblecontacts.com or
            privacy@contactscart.com with questions or concerns, we may not be able to respond to requests to
            exercise your rights under the CCPA. Because we only collect limited information about individuals
            without an account, we are unable to verify requests from non-accountholders to the standard required
            by the CCPA.
            Additionally, under California Civil Code Section 1798.83, California residents have the right to request in
            writing from businesses with whom they have an established business relationship, (a) a list of the
            categories of personal information that a business has disclosed to third parties during the immediately
            preceding calendar year for the third parties’ direct marketing purposes and (b) the names and
            addresses of such third parties. To exercise this right, please contact us at the email addresses above.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy
